<script>
    import {addSeconds, format} from 'date-fns'
    import getInstance from './../auth/httpService'
    import {onMount} from 'svelte';

    let state;
    let loading = false;
    let refreshing = false;
    $: username = state ? state.username || state.email : '';


    $: lastType = state ? state.bookingType : '';
    $: lastTypeLabel = lastType === "START" ? "started" : "stopped";
    $: nextTypeLabel = lastType === "START" ? "Stop" : "Start";
    $: dateFormatted = state ? format(new Date(state.timestamp), 'DD.MM.YYYY HH:mm') : ""
    $: workThisWeek = state ? format(addSeconds(new Date(0, 0), state.secondsThisWeek), 'HH:mm') : ""
    $: workToday = state ? format(addSeconds(new Date(0, 0), state.secondsToday), 'HH:mm') : ""

    async function track() {
        loading = true
        const client = await getInstance()
        await client.post('/api/track', {
            bookingType: lastType === "START" ? "STOP" : "START",
            timestamp: new Date().getTime()
        });
        await getLastItem()
        loading = false;
    }


    async function getLastItem() {
        const client = await getInstance()
        const res = await client.get('/api/user/last-action');
        state = res.data
    }
    async function getLastItemWithLoadingFlag() {
        refreshing = true
        await getLastItem()
        refreshing = false
    }

    onMount(() => {
        getLastItem()
    })

</script>

<style>
    :global(body) {
        background-color: #000;
        color: white;
    }

    h1, h3 {
        color: white;
    }

</style>
{#if !state}
    Loading ...
{:else}
    <h3>Hey {username}!</h3>
    <h5>Your working has <span class="font-weight-bold">{lastTypeLabel}</span></h5>
    <p>
    {#if loading === false}
        <button type="button" class="btn btn-primary" on:click={track}>{nextTypeLabel} tracking</button>
    {:else}
        <div class="spinner-border text-primary">Loading</div>
    {/if}
    </p>
    <p>
    {#if refreshing === false}
        <button type="button" class="btn btn-secondary" on:click={getLastItemWithLoadingFlag}>Refresh</button>
    {:else}
        <div class="spinner-border text-secondary">Loading</div>
    {/if}
    </p>
    {#if lastType === "START"}
        <p>You are working since {dateFormatted} </p>
    {/if}
    <p>This week, you worked {workThisWeek}</p>
    <p>Today, you worked {workToday}</p>
{/if}
